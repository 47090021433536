<template>
  <div class="yogi-ptsal">
    <!-- <h1>
      <feather-icon size="30" :icon="'PrinterIcon'" />
      LAPRORAN KEGIATAN OPERASIONAL ( Jasa Barang )
      <feather-icon size="30" :icon="'PrinterIcon'" />
    </h1> -->
    <b-progress
      v-if="isLoading"
      key="info"
      animated
      value="100"
      variant="info"
      :class="'progress-bar-info'"
    />
    <div v-if="isLoading">
      <hr />
      . . . Loading . . .
      <hr />
    </div>
    <form-wizard
      key="wizardKey"
      ref="wizardFirst"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      :lazy="true"
      shape="square"
      finish-button-text="Submit ( LAPRORAN KEGIATAN OPERASIONAL ( Jasa Barang ) )"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <tab-content
        title="Catatan Jasa Barang"
        :before-change="validationPhase2"
      >
        <validation-observer ref="phase2Rule" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Jasa Barang</h5>
              <small class="text-muted">Enter Your Jasa Barang.</small>
            </b-col>
            <b-col md="12">
              <validation-provider
                #default="{ errors }"
                name="Catatan Kegiatan"
                rules="required"
              >
                <b-form-group
                  label="Catatan Kegiatan"
                  label-for="nomor-surat-jalan"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="nomor-surat-jalan"
                    v-model="form.catatan_kegiatan"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Catatan Kegiatan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Rencana Tanggal Berkegiatan"
                label-for="perisiapan-berkegiatan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Rencana Tanggal Berkegiatan"
                  rules="required"
                >
                  <!-- <b-form-input
                  id="perisiapan-berkegiatan"
                  v-model="form.wkt_keg_rencana"
                  placeholder="Waktu Perisiapan Berkegiatan"
                  :state="errors.length > 0 ? false:null"
                /> -->
                  <flat-pickr
                    v-model="form.wkt_keg_rencana"
                    class="form-control"
                    static="true"
                    :state="errors.length > 0 ? false : null"
                    :config="
                      $router.currentRoute.params.id !== undefined
                        ? dpconfigRevisi
                        : dpconfig
                    "
                    @on-change="onStartChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col class="mt-1">
              <strong><feather-icon :icon="'ClockIcon'" /> </strong>
              <i>
                <strong> [ {{ $t('quotes-jasa_barang_edt1') }} ] </strong>
              </i>
              <hr />

              <strong><feather-icon :icon="'BarChartIcon'" /></strong>
              <strong
                ><i> [ {{ $t('quotes-jasa_barang_edt2') }} ] </i></strong
              >
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <template slot-scope="props" slot="footer">
        <div class="wizard-footer-left">
          <wizard-button
            v-if="props.activeTabIndex > 0 && !props.isLastStep"
            @click.native="props.prevTab()"
            :style="props.fillButtonStyle"
            >Previous</wizard-button
          >
        </div>
        <div class="wizard-footer-right">
          <wizard-button
            v-if="!props.isLastStep"
            @click.native="props.nextTab()"
            class="wizard-footer-right"
            :style="props.fillButtonStyle"
            >Next</wizard-button
          >

          <wizard-button
            v-else
            @click.native="formSubmitted"
            class="wizard-footer-right finish-button"
            :style="props.fillButtonStyle"
            :disabled="isLoading"
          >
            <b-spinner v-if="isLoading" small></b-spinner>
            <feather-icon v-else icon="CalendarIcon" class="mr-50" />
            <strong>
              {{
                props.isLastStep
                  ? !!dataID
                    ? 'Simpan ( LAPRORAN KEGIATAN OPERASIONAL ( Jasa Barang ) )'
                    : 'Submit ( LAPRORAN KEGIATAN OPERASIONAL ( Jasa Barang ) )'
                  : 'Next'
              }}
            </strong>
          </wizard-button>
        </div>
      </template>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BCardText,
  BAvatar,
  BBadge
} from 'bootstrap-vue'
import { required, email } from '@validations'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BAvatar,
    BBadge,
    Indonesian,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    WizardButton,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BCardActions,
    BCardText,
    Cleave
  },
  data() {
    return {
      onceRender: false,
      jenis_produk: 0,
      dataID: this.$router.currentRoute.params.id,
      isExistDetailID: !!this.$router.currentRoute.params.id /* Boolean */,
      isLoading: false,
      form: {
        wkt_keg_rencana: null,
        catatan_kegiatan: null
      },
      options: {
        number: {
          // numeral: true,
          // numeralThousandsGroupStyle: 'thousand',
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.'
          // suffix: 'LTR',
        }
      },
      dpconfig: {
        // default
        wrap: true,
        enableTime: true,
        time_24hr: true,
        altInput: true,
        altFormat: 'j F Y - H:i',
        dateFormat: 'Y-m-d H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: null
      },
      dpconfigRevisi: {
        wrap: true,
        enableTime: true,
        time_24hr: true,
        altInput: true,
        altFormat: 'j F Y - H:i',
        dateFormat: 'Y-m-d H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: null
      },
      configs: {
        end: {
          wrap: true,
          altInput: true,
          enableTime: true,
          time_24hr: true,
          dateFormat: 'Y-m-d H:i',
          altInput: true,
          altFormat: 'j F Y - H:i',
          locale: Indonesian,
          minDate: null
        }
      }
    }
  },
  watch: {},
  computed: {},
  async created() {
    await this.fetchData()
  },
  methods: {
    moment,
    async fetchData() {
      const uriAPI = router.currentRoute.params.api
      const dataID = this.dataID

      if (dataID !== undefined) {
        this.isLoading = true

        await useJwt.http.get(`${uriAPI}/${dataID}`).then((response) => {
          const { opr_form_kegiatan_laporan } = response.data
          this.isLoading = false
          this.form = opr_form_kegiatan_laporan
        })
      }
    },
    durationAsString(start, end) {
      const duration = moment.duration(moment(end).diff(moment(start)))

      //Get Days
      const days = Math.floor(duration.asDays()) // .asDays returns float but we are interested in full days only
      const daysFormatted = days ? `${days} Days ` : '' // if no full days then do not display it at all

      //Get Hours
      const hours = duration.hours()
      const hoursFormatted = `${hours} Hours `

      //Get Minutes
      const minutes = duration.minutes()
      const minutesFormatted = `${minutes} Minutes`

      /* produktivitas */
      const decDays = Number(days) * 24
      const decMinutes = Number(minutes) / 60
      const decimalValue = Number(decDays) + Number(hours) + Number(decMinutes)
      // const decimalDuration = ` | Decimal Value : ${decimalValue.toFixed(2)}`

      const produktivitas = Number(this.form.volume) / Number(decimalValue)
      this.form.wkt_produktivitas = produktivitas.toFixed(2)

      return [
        daysFormatted,
        hoursFormatted,
        minutesFormatted
        // decimalDuration
      ].join('')
    },
    onStartChange(selectedDates, dateStr, instance, model) {
      console.log('dateStr', dateStr)
      this.configs.end.minDate = dateStr
    },
    onEndChange(selectedDates, dateStr, instance) {
      this.dpconfig.maxDate = dateStr
    },
    async formSubmitted() {
      try {
        this.isLoading = true
        // const payload = {
        //   ...this.form,
        // }
        if (!!this.dataID) {
          await useJwt.http
            .put(`opr_form_kegiatan_laporan/${this.dataID}`, this.form)
            .then(() => {
              this.isLoading = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Form Updated',
                  icon: 'EditIcon',
                  variant: 'success'
                }
              })
              this.$router.go(-1)
            })
            .catch((error) => {
              this.isLoading = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Form Submitted',
                  icon: 'XCircleIcon',
                  variant: 'danger'
                }
              })
            })
        } else {
          await useJwt.http
            .post('opr_form_kegiatan_laporan', this.form)
            .then(() => {
              this.isLoading = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Form Submitted',
                  icon: 'SaveIcon',
                  variant: 'success'
                }
              })
              this.$router.go(-1)
            })
            .catch((error) => {
              this.isLoading = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Form Submitted',
                  icon: 'XCircleIcon',
                  variant: 'danger'
                }
              })
            })
        }
      } catch (error) {
        console.log('an error on sent notification :: ', error)
      }
    },
    validationPhase1() {
      return new Promise((resolve, reject) => {
        this.$refs.phase1Rule.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationPhase2() {
      return new Promise((resolve, reject) => {
        this.$refs.phase2Rule.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationPhase3() {
      return new Promise((resolve, reject) => {
        this.$refs.phase3Rule.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
</style>

<style lang="scss" scoped>
.yogi-ptsal {
  .yo-card {
    &:hover {
      transition: all 0.1s;
      scale: 1.1;
      box-shadow: 0 4px 8px rgb(0, 119, 255);
      opacity: 0.9;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
